import React from 'react';
import styled from 'styled-components';
import { useLanguage, passLanguage } from '../components/language-context';
import { Layout } from '../components/layout';
import { Section } from '../components/section';
import { media } from '../styles/media-query-template';

const Row = styled.div`
  width: 100%;
  font-family: "Proxima Nova", Lato, sans-serif;
  ${media.medium`
    margin: 0;
  `}
`

const CenteredRow = styled(Row)`
  text-align: center;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Proxima Nova", Lato, sans-serif;
  text-align: center;
  width: 100%;
  ${media.medium`
    width: 75%;
    padding: 20px;
    font-size: 2rem;
  `}
`

const SubTitle = styled.div`
  font-family: "Proxima Nova", Lato, sans-serif;
  text-align: start;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 1rem;
  line-height: 1.2;
  ${media.medium`
    font-size: 1.5rem;
    line-height: 1.5;
    &::after{
      margin-top: 20px !important;
    }
  `}
  &::after{
    content: "";
    display: block;
    width: 100px;
    height: 6px;
    background: #000;
    margin-top: 12px;
  }
`

const Paragraph = styled(Row)`
  padding: 25px 0;
  font-size: 16px;
  letter-spacing: .4px;
  font-weight: 400;
  text-align: justify;
  ${media.medium`
    padding: 40px 0;
    width: 75%;
  `}
`

const EffectiveDate = styled(Row)`
  padding: 16px 0px;
  font-weight: bold;
`
const Underlined = styled.span`
  text-decoration: underline;
`

const SimpleLink = styled.a`
  color: black;
`
const LineBreak = () => (
  <>
    <br/><br/>
  </>
);

const SubSection = ({children, title}) => (
  <>
    <CenteredRow>
      <Title>{title}</Title>
    </CenteredRow>
    {children}
  </>
);


const TermAndConditionPageBase = useLanguage(({location, lang, msg}) => {
  const title = msg('terms-title');
  const policiesLink = 'https://www.forrester.com/policies/standard-contractual-clauses/';
  return (
    <Layout location={location}>
      <Section title={title}>
        
        <EffectiveDate>{msg('terms-effective-date')}</EffectiveDate>
        <Paragraph>
          <SubTitle>1. {msg('terms-p1-subtitle')}</SubTitle>
          {msg('terms-p1-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>2. {msg('terms-p2-subtitle')}</SubTitle>
          {msg('terms-p2-content')}
          <LineBreak />
          a)  {msg('terms-p2-content-a')}
          <LineBreak />
          b)  {msg('terms-p2-content-b')}
          <LineBreak />
          c)  {msg('terms-p2-content-c')}
          <LineBreak />
          d)  {msg('terms-p2-content-d')}
          <LineBreak />
          e)  {msg('terms-p2-content-e')}
          <LineBreak />
          f)  {msg('terms-p2-content-f')}
          <LineBreak />
          g)  {msg('terms-p2-content-g')}
          <LineBreak />
          h)  {msg('terms-p2-content-h')}
          <LineBreak />
          i)  {msg('terms-p2-content-i')}
        </Paragraph>

        <Paragraph>
          <SubTitle>3. {msg('terms-p3-subtitle')}</SubTitle>
          {msg('terms-p3-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>4. {msg('terms-p4-subtitle')} </SubTitle>
          {msg('terms-p4-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>5. {msg('terms-p5-subtitle')} </SubTitle>
            {msg('terms-p5-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>6. {msg('terms-p6-subtitle')} </SubTitle>
            {msg('terms-p6-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>7. {msg('terms-p7-subtitle')} </SubTitle>
            {msg('terms-p7-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>8. {msg('terms-p8-subtitle')} </SubTitle>
            {msg('terms-p8-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>9. {msg('terms-p9-subtitle')}</SubTitle>
          {msg('terms-p9-content-light')} <b>{msg('terms-p9-content-bold')}</b>
        </Paragraph>

        <Paragraph>
          <SubTitle>10. {msg('terms-p10-subtitle')}</SubTitle>
          {msg('terms-p10-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>11. {msg('terms-p11-subtitle')}</SubTitle>
          a)  {msg('terms-p11-content-a')}
          <LineBreak />
          b)  {msg('terms-p11-content-b')}
        </Paragraph>

        <Paragraph>
          <SubTitle>12. {msg('terms-p12-subtitle')}</SubTitle>
          {msg('terms-p12-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>13. {msg('terms-p13-subtitle')}</SubTitle>
         {msg('terms-p13-content')}
        </Paragraph>

        <Paragraph>
          <SubTitle>14. {msg('terms-p14-subtitle')}</SubTitle>
          <b>(a) <Underlined>{msg('terms-p14-subtitle-a')}</Underlined>.</b> {msg('terms-p14-content-a')}
          <LineBreak />
          <b>(b) <Underlined>{msg('terms-p14-subtitle-b')}</Underlined>.</b> {msg('terms-p14-content-b-1')} <SimpleLink href={policiesLink}>{policiesLink}</SimpleLink> {msg('terms-p14-content-b-2')}
        </Paragraph>

        <Paragraph>
          <SubTitle>15. {msg('terms-p15-subtitle')}</SubTitle>
          {msg('terms-p15-content')}
        </Paragraph>

        <SubSection title={msg('terms-additional-title')}>
          <Paragraph>
            <SubTitle>1. {msg('terms-additional-p1-title')}</SubTitle>
            <b>1.1. </b> {msg('terms-additional-p1-content-1')}
          </Paragraph>

          <Paragraph>
            <SubTitle>2. {msg('terms-additional-p2-title')}</SubTitle>
            <b>2.1. </b>{msg('terms-additional-p2-content-1')}
            <LineBreak />
            <b>2.2. </b>{msg('terms-additional-p2-content-2')}
            <LineBreak />
            <b>2.3. </b>{msg('terms-additional-p2-content-3')}
          </Paragraph>

          <Paragraph>
            <SubTitle>3. {msg('terms-additional-p3-title')}</SubTitle>
            <b>3.1. </b> {msg('terms-additional-p3-content-1')}
            <LineBreak />
            <b>3.2. </b> {msg('terms-additional-p3-content-2')}
            <LineBreak />
            <b>3.3. </b> {msg('terms-additional-p3-content-3')}
            <LineBreak />
            <b>3.4. </b> {msg('terms-additional-p3-content-4')}
          </Paragraph>

          <Paragraph>
            <SubTitle>4. {msg('terms-additional-p4-title')}</SubTitle>
            <b>4.1. </b> {msg('terms-additional-p4-content-1')}
            <LineBreak />
            <b>4.2. </b> {msg('terms-additional-p4-content-2')}
            <LineBreak />
            <b>4.3. </b> {msg('terms-additional-p4-content-3')}
            <LineBreak />
            <b>4.4. </b> {msg('terms-additional-p4-content-4')}
            <LineBreak />
            <b>4.5. </b> {msg('terms-additional-p4-content-5')}
          </Paragraph>

          <Paragraph>
            <SubTitle>5. {msg('terms-additional-p5-title')}</SubTitle>
            <b>5.1. </b> {msg('terms-additional-p5-content-1')}
            <LineBreak />
            <b>5.2. </b> {msg('terms-additional-p5-content-2')}
            <LineBreak />
            <b>5.3. </b> {msg('terms-additional-p5-content-3')}
            <LineBreak />
            <b>5.4. </b> {msg('terms-additional-p5-content-4')}
          </Paragraph>

          <Paragraph>
            <SubTitle>6. {msg('terms-additional-p6-title')}</SubTitle>
            <b>6.1. </b> {msg('terms-additional-p6-content-1')}
            <LineBreak />
            <b>6.2. </b> {msg('terms-additional-p6-content-2')}
            <LineBreak />
            <b>6.3. </b> {msg('terms-additional-p6-content-3')}
          </Paragraph>

          <Paragraph>
            <SubTitle>7. {msg('terms-additional-p7-title')}</SubTitle>
            <b>7.1. </b> {msg('terms-additional-p7-content-1')}
          </Paragraph>

          <Paragraph>
            <SubTitle>8. {msg('terms-additional-p8-title')}</SubTitle>
            <b>8.1. </b> {msg('terms-additional-p8-content-1')}
            <LineBreak />
            <b>8.2. </b> {msg('terms-additional-p8-content-2')}
          </Paragraph>

          <Paragraph>
            <SubTitle>9. {msg('terms-additional-p9-title')}</SubTitle>
            <b>9.1. </b> {msg('terms-additional-p9-content-1')}
            <LineBreak />
            <b>9.2. </b> {msg('terms-additional-p9-content-2')}
          </Paragraph>

          <Paragraph>
            <SubTitle>10. {msg('terms-additional-p10-title')}</SubTitle>
            <b>10.1. </b> {msg('terms-additional-p10-content-1')}
          </Paragraph>
        </SubSection>
      </Section>
    </Layout>
  );
});

const TermAndConditionPage = passLanguage(TermAndConditionPageBase);

export default TermAndConditionPage;